import React from 'react';
import PropTypes from "prop-types";
import { graphql, StaticQuery } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';


const SubpageHero = ( { title, breadcrumb } ) => (
  <StaticQuery
  query={graphql`
    query {
      desktop: file(relativePath: { eq: "home-background.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `}
  render={data => {
    // Set ImageData.
    const imageData = data.desktop.childImageSharp.fluid
    return (
      <BackgroundImage
        Tag="section"
        className="subpage-hero relative flex flex-wrap items-end mb-16 md:mb-24"
        fluid={imageData}
      > 
        <section className="relative container mx-auto px-2 md:px-4 mb-10 text-white z-10">
          <h2 className="mb-6 text-4xl md:text-6xl font-extrabold leading-tight">
            {title}
          </h2>
          {breadcrumb}
        </section>
        <div className="absolute top-0 left-0 w-full h-full bg-primary opacity-75"/>
      </BackgroundImage>
    )
  }}
/>
);

SubpageHero.propTypes = {
  title: PropTypes.string,
  breadcrumb:  PropTypes.element,
};

export default SubpageHero;